<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="showModalCreate = true" />
              <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedRoles || !selectedRoles.length"
              />
            </div>
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="roles"
          v-model:selection="selectedRoles"
          dataKey="_id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Role List</h5>
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search..."
                />
              </span> -->
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="name" header="Name" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Access List">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              <span v-for="data in slotProps.data.access_list" :key="data._id">
                {{ data.title + " | " }}
              </span>
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Actions">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="showEditModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <!-- create -->
        <Dialog v-model:visible="showModalCreate" :style="{ width: '450px' }" header="Add Role" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Role Name :</label>
            <InputText id="name" v-model.trim="form.name" required="true" autofocus />
          </div>

          <MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Select Countries">
            <template #value="slotProps">
              <div
                class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2"
                v-for="option of slotProps.value"
                :key="option.code"
              >
                <div>{{ option.title }}</div>
              </div>
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                <div class="p-1">Access List</div>
              </template>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.title }}</div>
              </div>
            </template>
          </MultiSelect>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />
            <Button label="Create" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="createRole" />
          </template>
        </Dialog>

        <!-- update -->
        <Dialog v-model:visible="showUpdateModal" :style="{ width: '450px' }" header="Add Role" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Role Name :</label>
            <InputText id="name" v-model.trim="update_form.name" required="true" autofocus />
          </div>

          <MultiSelect
            v-model="update_form.access_list"
            :options="multiselectValues"
            optionLabel="name"
            placeholder="Select Countries"
          >
            <template #value="slotProps">
              <div
                class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2"
                v-for="option of slotProps.value"
                :key="option._id"
              >
                <div>{{ option.title }}</div>
              </div>
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                <div class="p-1">Access List</div>
              </template>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.title }}</div>
              </div>
            </template>
          </MultiSelect>
          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showUpdateModal = false" />
            <Button
              label="Create"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="updateRole(update_form._id, update_form)"
            />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <!-- Delete Modal -->
        <Dialog v-model:visible="showModalDelete" :style="{ width: '450px' }" header="Delete Role Confirm ?" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to delete the selected Role?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="deleteMultipleRole" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../../service/ProductService";
import RoleApi from "../../api/Role";
import SubMenuApi from "../../api/SubMenu";

export default {
  data() {
    return {
      products: null,
      submenu_list: [],
      showModalCreate: false,
      deleteProductDialog: false,
      showUpdateModal: false,
      showModalDelete: false,
      product: {},
      roles: [],
      selectedRoles: [],
      isLoading: false,
      form: {
        name: "",
      },
      update_form: {
        name: "",
        access_list: [],
      },
      role: [
        { label: "ADMIN", value: "admin" },
        { label: "USER", value: "user" },
      ],
      multiselectValue: null,
      updateMultiselectValue: null,
      multiselectValues: [],
    };
  },
  productService: null,
  created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  mounted() {
    this.getSubMenu();
    this.productService.getProducts().then((data) => (this.products = data));
    this.getRoles();
  },
  methods: {
    async getSubMenu() {
      try {
        const response = await SubMenuApi.Get();
        if (response.data.status === 200) {
          this.submenu_list = response.data.data;
          let temp_data = [];

          this.submenu_list.map((data) => {
            temp_data.push(data);
          });

          this.multiselectValues = temp_data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getRoles() {
      try {
        const response = await RoleApi.Get(this.form);
        if (response.data.status === 200) {
          this.roles = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createRole() {
      this.isLoading = true;
      try {
        const response = await RoleApi.Add({
          ...this.form,
          access_list: this.multiselectValue,
        });
        if (response.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "New Role Created!",
            life: 3000,
          });

          this.getRoles();
          this.isLoading = false;
          this.form.name = "";
          this.showModalCreate = false;
          this.multiselectValue = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateRole(id, data) {
      this.isLoading = true;
      try {
        const response = await RoleApi.Update(id, data);
        if (response.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Role Updated!",
            life: 3000,
          });
          this.getRoles();
          this.isLoading = false;
          this.update_form = {
            name: "",
            access_list: [],
          };
          this.showUpdateModal = false;
          this.multiselectValue = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteMultipleRole() {
      this.isLoading = true;
      try {
        //   reset selected roles
        let payload = {
          id: [],
        };

        for (let i = 0; i < this.selectedRoles.length; i++) {
          payload.id.push(this.selectedRoles[i]._id);
        }

        const response = await RoleApi.DeleteMultiple(payload);
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: response.data.message,
            life: 3000,
          });
          this.getRoles();
          this.isLoading = false;
          this.selectedRoles = [];
          this.showModalDelete = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    showEditModal(data) {
      this.update_form = data;
      this.showUpdateModal = true;
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    confirmDeleteSelected() {
      this.showModalDelete = true;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
