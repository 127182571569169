import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/menu/submenu");
  },
  //   getDetail(id) {
  //     return Api().get(`/menu/submenu/${id}`);
  //   },
  //   GetOptionmenu/submenuRole(role) {
  //     return Api().get(`/menu/submenu/getmenu/submenu?role=${role}`);
  //   },
  Update(id, data) {
    return Api().put(`/menu/submenu/${id}`, data);
  },
  Add(data) {
    return Api().post(`/menu/submenu/`, data);
  },
  Delete(id) {
    return Api().delete(`/menu/submenu/${id}`);
  },
  DeleteMultiple(data) {
    return Api().post(`/menu/submenu/all`, data);
  },
  //   UploadImage(id, data) {
  //     return Api().post(`menu/submenu/upload/${id}`, data);
  //   },
};
